// Note: Requires flexslider 2
jQuery(document).ready(function($) {
	$('.neighborhoods-slider-outer-wrapper').each(function() {
		var autoScroll = false;
        if($(this).hasClass('auto-scroll')){
            autoScroll = true;
        }

        var scrollSpeed = 300;
        if($(this).hasClass('slow-speed')){
            scrollSpeed = 1000;
        } else if($(this).hasClass('medium-speed')){
            scrollSpeed = 700;
        } else if($(this).hasClass('fast-speed')){
            scrollSpeed = 300;
        }

		$(this).find('.row-neighborhoods-slider .flexslider .slides').each(function() {
			$(this).slick({
				appendArrows: $(this).parent().find('.slick-control-wrapper'),
				appendDots: $(this).parent().find('.slick-control-wrapper'),
				dots: true,
				arrows: false,
				autoplay: autoScroll,
				autoplaySpeed: 5000,
				cssEase: 'linear',
				speed: scrollSpeed,
				slidesToShow: 1,
				responsive: [{
					breakpoint: 768,
					settings: {
						arrows: false,
						slidesToShow: 1,
					}
				}]
			});			
		});
	});
}); /* end of as page load scripts */